<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Attendance 🗓️
    </h2>
    <p class="mt-0 mb-0 sub-title-style">
      Explore Checkins and pending attendance here.
    </p>
    <v-tabs
      v-model="tab"
      class="mt-5"
    >
      <v-tab>
        CHECK-INS
      </v-tab>
      <v-tab>
        PENDING
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
    >
      <v-tab-item>
        <onsite-attendance />
      </v-tab-item>
      <v-tab-item>
        <offsite-attendance />
      </v-tab-item>
    </v-tabs-items>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
import OnsiteAttendance from './OnsiteAttendance.vue';
import OffsiteAttendance from './OffsiteAttendance.vue';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

export default {
    name: 'Attendance',
    components: {
        'onsite-attendance': OnsiteAttendance,
        'offsite-attendance': OffsiteAttendance,
        'centre-spinner': spinner,
    },
    data: () => ({
        tab: null,
        showForm: false,
        loading: false,
    }),
    computed: {
        companiesList () {
            return this.$store.getters['companies/getCompaniesList'];
        },
    },
    watch: {
        isSearchAdded (val) {
          if (val === false) {
            this.getAllRecordsFromServer({ itemsPerPage: 10, pageStart: 0 });
          }
        },
      },
    async mounted () {
        await this.getCompaniesList();
        this.loading = false;
    },
    methods: {
        async getCompaniesList () {
            this.loading = true;
            await this.$store.dispatch('companies/fetchCompaniesList');
        },
    },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
  font-size: 36px;
  font-weight: 600;
}
.icon-style {
 color: #37474F;
 font-size: 45px;
}
.my-tabs-background-style {
 background-color: #fff !important;
}
.v-data-table::v-deep th {
 font-size: 12px !important;
 color: #37474F !important;
 font-weight: bold !important;
}
.v-data-table::v-deep td {
 font-size: 13px !important;
 color: #37474F !important;
}
.v-data-table { background-color: #ECEFF1; }
.v-card__text{
  padding: 30px!important;
}
.v-card__text{
  padding: 30px!important;
}
.sub-title-style{
  color: #aaa;
}
</style>
